import {connectSearchBox} from "react-instantsearch-dom";
import {SearchBoxProvided} from "react-instantsearch-core";
import {Box, Button, InputBase} from "@mui/material";
import React, {ChangeEvent, useState} from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import {useOnline} from "@lib/hooks/use-online";
import {useThrottle} from "@lib/hooks/use-throttle";
import {useRouter} from "next/router";
import {isDefined} from "@lib/utils/array-utils";
import {useSelectedSearchResultIndex} from "@lib/hooks/use-selected-search-result-index";
import {Key} from "@components/key";

interface Props {
    onClose?: () => void
}

/**
 * @description replace route using window.history API instead of next/router to prevent re-renders
 * @see https://github.com/vercel/next.js/discussions/18072
 * @param {string} parameter url parameter to change
 * @param {string} value value to apply
 */
export const setQueryParameter = (parameter: string, value: string) => {
    if(typeof window === 'undefined'){
        throw new Error("The variable window is undefined, you are likely calling this function from the server.")
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const as = window.history.state.as as string
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const url = window.history.state.url as string

    const parameterPattern = new RegExp (`${parameter}=.*?(?=&|$)`)
    const match = parameterPattern.exec(url)

    const newUrl = !isDefined(match) ? (
        `${url}${parameter}=${value}`
    ) : (
        url.replace (
            new RegExp (`${parameter}=.*?(?=&|$)`),
            `${parameter}=${value}`
        )
    )

    window.history.replaceState (
        {
            ...window.history.state,
            'as': newUrl,
            'url': newUrl,
        },
        '',
        newUrl
    )

}

const MatSearchBox = ({refine, onClose}: Pick<SearchBoxProvided, 'refine'> & Props) => {
    const isOnline = useOnline()
    const router = useRouter()
    const [text, setText] = useState<string | undefined>(router.query.text as unknown as string)
    const search = (textQuery: string) => {
        if(window){
            setQueryParameter('text', textQuery)
        }
        refine(textQuery)
    }
    const throttledRefine = useThrottle<string, void>(search, 333)
    const handleInput = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const textQuery = e.currentTarget.value
        setText(textQuery)
        throttledRefine(textQuery)
    }

    const {increment, decrement} = useSelectedSearchResultIndex()

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
        switch (event.code){
            case 'ArrowDown':
                increment()
                break
            case 'ArrowUp':
                decrement()
                break
        }
    }

    return (
        <Box component='form' sx={{
            display: 'flex',
            alignItems: 'center',
            px: 2,
        }}>
            <SearchIcon color='action' />
            <InputBase
                autoFocus
                placeholder={isOnline ? 'Search' : 'Please connect to the internet.'}
                inputProps={{"aria-label": "Search"}}
                value={text}
                sx={{ marginLeft: 1, flex: 1, my: 2}}
                onKeyDown={handleKeyDown}
                onChange={handleInput}
                disabled={!isOnline}
            />
            {
                onClose && (
                    <Button
                        color="inherit"
                        sx={{p: 2}}
                        aria-label="Exit search"
                        onClick={onClose}
                        endIcon={<CloseIcon/>}
                    >
                        <Key>ESC</Key>
                    </Button>
                )
            }
        </Box>
    )
}


export const SearchBox = (props: Props) =>  React.createElement(
    connectSearchBox((providedProps: SearchBoxProvided) => (
        <MatSearchBox {...props} {...providedProps} />)
    )
)
