import React from "react";
import {APP_BAR_MAX_HEIGHT, DRAWER_WIDTH} from "@components/layout/generic-layout";
import {Box} from "@mui/material";

interface Props {
    children: React.ReactNode
    isOpen: boolean
}

export const DesktopDrawer = ({children, ...props}: Props) => (
    <Box
        sx={{
            zIndex: 'appbar',
            display: props.isOpen ? 'flex' : 'none',
            maxWidth: DRAWER_WIDTH,
            flexShrink: 0,
            position: 'sticky',
            top: APP_BAR_MAX_HEIGHT,
        }}
    >
        {children}
    </Box>
)