import {HitsProvided} from "react-instantsearch-core";
import {DocsearchHit, SearchRecord} from "@lib/algolia/record-model";
import React from "react";
import {connectHits} from "react-instantsearch-dom";
import {Container} from "@mui/material";
import {HitPreview} from "@components/search/mat-search/hit-preview";
import {touchOnlyDevice} from "@lib/jss/breakpoints";
import {useHotkeys} from "react-hotkeys-hook";
import {useSelectedSearchResultIndex} from "@lib/hooks/use-selected-search-result-index";
import {styled} from "@mui/material";
import {HitList} from "@components/search/mat-search/hit-list";

export const SplitContainer = styled('div')({
    display: 'flex',
    overflow: 'auto',
    flexGrow: 1,
})

export const PreviewContainer = styled(Container)(({theme}) => ({
    [touchOnlyDevice]: {
        display: 'none',
    },
    overflowY: 'inherit',
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
        display: 'none',
    },
}))

export const ResultsWithPreview = connectHits(
    ({hits}: HitsProvided<SearchRecord>) => {
        const {
            selectedIndex,
            setSelectedIndex,
            maxIndex,
            setMaxIndex,
            increment,
            decrement,
        } = useSelectedSearchResultIndex()
        setMaxIndex(hits.length - 1)
        const selectedHit = hits[selectedIndex] as DocsearchHit | undefined

        useHotkeys('down', increment, [selectedIndex])
        useHotkeys('up', decrement, [selectedIndex])

        const handleSelect = (index: number) => {
            setSelectedIndex(index)
        };

        return (
                <SplitContainer>
                    <HitList
                        hits={hits}
                        selectedIndex={selectedIndex}
                        onSelect={handleSelect}
                    />
                    <PreviewContainer maxWidth='sm'>
                        <HitPreview hit={selectedHit}/>
                    </PreviewContainer>
                </SplitContainer>
        )
    }
)

