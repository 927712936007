import { Theme, ThemeProvider} from '@mui/material/styles';


import React, {ComponentType, ReactNode} from "react";
import {Box, Hidden, SxProps, useTheme} from "@mui/material";
import {GenericLayoutProps} from '@lib/content-component/layout-props'
import DefaultFooter from "@components/layout/footer";
import {MobileDrawer} from "@components/layout/drawer";
import {DesktopDrawer} from "@components/layout/desktop-drawer";
import {HiddenProps} from "@mui/material/Hidden";
import {PfxAppBar} from "@components/layout/header";
import {useEmbedded} from "@lib/hooks/use-embedded";
import {getContrastTheme} from "@lib/styles/themes";
import {styled} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";

export const HiddenCss: ComponentType<Omit<HiddenProps, 'implementation'>> = (props) => (
    <Hidden implementation='css' {...props} />
)

export const DRAWER_WIDTH = 240
export const APP_BAR_MAX_HEIGHT = 64

const Main = styled('div')(({theme})=>({
    flex: 1,
    minHeight: `calc(100vh - ${APP_BAR_MAX_HEIGHT}px)`, // Force user to scroll to see see footer
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create(['background-color', 'color', 'box-shadow']),
}))


export const GenericLayout = ({
                                  children,
                                  leftDrawerContent,
                                  rightDrawerContent,
                                  fixedMenu,
                                  title,
                                  footer,
                              }: GenericLayoutProps
) => {
    const isDrawerEnabled = leftDrawerContent !== undefined
    const isEmbedded = useEmbedded()

    const theme = useTheme()

    return isEmbedded ? (
        <Main>
            {children}
        </Main>
    ) : (
        <>

            <ThemeProvider theme={getContrastTheme(theme)}>
                <PfxAppBar title={title} fixedMenu={fixedMenu} isDrawerEnabled={isDrawerEnabled}/>
                <Toolbar/>
            </ThemeProvider>
            <MobileDrawer>
                <MobileDrawerContent leftContent={leftDrawerContent}/>
            </MobileDrawer>
            {/*</MuiThemeProvider>*/}
            <Box display='flex'>
                <HiddenCss smDown smUp={!leftDrawerContent}>
                    <DesktopDrawer isOpen={leftDrawerContent !== undefined}>
                        <DrawerContent sx={{maxHeight: `calc(100vh - ${APP_BAR_MAX_HEIGHT}px)`, paddingBottom: 8}}>
                            {leftDrawerContent}
                        </DrawerContent>
                    </DesktopDrawer>
                </HiddenCss>
                <Main>
                    {children}
                </Main>
                <HiddenCss mdDown mdUp={!rightDrawerContent}>
                    <DesktopDrawer isOpen={rightDrawerContent !== undefined}>
                        <DrawerContent>{rightDrawerContent}</DrawerContent>
                    </DesktopDrawer>
                </HiddenCss>
            </Box>
            {footer ?? <DefaultFooter/>}
        </>
    );
}

// TODO change menuData to ReactNode
const MobileDrawerContent = ({leftContent}: { leftContent: React.ReactNode }) => (
    <DrawerContent>
        <Box display='flex' flexDirection='column'/>
        {leftContent}
    </DrawerContent>
)

const DrawerContent = ({sx, ...props}: {children: ReactNode, sx?: SxProps<Theme>}) => (
    <Box sx={{width: DRAWER_WIDTH, overflowX: 'hidden', ...(sx ?? {})}} {...props} />
)

