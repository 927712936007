export const isDefined = <T>(item: T | undefined | null): item is T => {
    // eslint-disable-next-line no-null/no-null
    return item !== undefined && item !== null
}

type ValueOf<T> = T[keyof T];

export const groupBy = <T extends Record<string, any>, K extends string>(list: T[], key: K): Record<T[K], T[]> => {
    const record = {} as Record<T[K], T[]>;
    list.forEach((item) => {
        const collection = record[item[key]];
        if (!collection) {
            record[item[key]] = [item];
        } else {
            collection.push(item);
        }
    });
    return record;
}