import {Theme} from "@mui/material/styles";
import {ThemeColor, Variant} from "@lib/styles/types";
import {lineBorder} from "@lib/styles/theme-utils-2";
import {getContrastTheme} from "@lib/styles/themes";

export const typographyMixin = (theme: Theme, variant: Variant) => ({
    fontFamily: theme.typography[variant].fontFamily,
    // Mui's styled() has a bug in its typing it seems, and fails unless we pretend this is a number
    fontWeight: theme.typography[variant].fontWeight as number,
    fontSize: theme.typography[variant].fontSize,
    lineHeight: theme.typography[variant].lineHeight,
    letterSpacing: theme.typography[variant].letterSpacing,
    textTransform: theme.typography[variant].textTransform,
    color: theme.typography[variant].color,
})

export const contrastBackgroundMixin = (theme: Theme, themeColor: ThemeColor = 'primary') => ({
    backgroundColor: theme.palette.mode === 'light' ? theme.palette[themeColor].main : theme.palette[themeColor].dark,
    // background: theme.palette.mode === 'light' ?
    //     linearGradient(theme.palette[themeColor].main, theme.palette[themeColor].light)
    //     :
    //     linearGradient(theme.palette[themeColor].dark, theme.palette[themeColor].main),
    color: theme.palette[themeColor].contrastText,
})

export const backgroundMixin = (theme: Theme) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
})

export const backgroundMixinEmotion = {
    bgcolor: 'background.default',
    // color: 'text.primary',
}

export const getAppBarTheme = (theme: Theme) => (
    getContrastTheme(theme)
)

export const getAppBarColor = (theme: Theme): string => (
    getAppBarTheme(theme).palette.background.default
)

export const keyMixin = (theme: Theme, color?: string) => ({
    ...typographyMixin(theme,'caption'),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    border: lineBorder( color ?? theme.palette.divider),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    color: color,
})
