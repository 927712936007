import {Fade, ListItem, ListItemText} from "@mui/material";
import {DocsearchHit} from "@lib/algolia/record-model";
import {useSearchDialogOpener} from "@lib/hooks/use-search-dialog-opener";
import {HitListItem} from "@components/search/mat-search/hit-list-item";
import List from "@mui/material/List";
import React from "react";
import {styled} from "@mui/material";

interface HitListProps {
    hits: DocsearchHit[]
    selectedIndex: number
    onSelect: (index: number) => void
}


const Root = styled(List)(({theme}) => ({
    '& .ais-Snippet-highlighted': {
        // color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
    },
}))

export const HitList = ({hits, selectedIndex, onSelect}: HitListProps) => {
    const [_, setSearchDialogOpen] = useSearchDialogOpener()

    const handleClick = () => {
        setSearchDialogOpen(false)
    }

    let previousType: string | undefined = undefined

    const items = hits.length === 0 ? [1,2,3,4,5,6,7,8,9,10].map(i => <HitListItem key={i}/>) : hits.map((hit, index) => {
        const currentType = hit.type

        const hitItem = (
            <HitListItem
                hit={hit}
                selected={selectedIndex === index}
                onSelect={() => onSelect(index)}
                onClick={handleClick}
            />
        )

        const overline = previousType !== currentType && (
            <ListItem
                dense
                sx={{py: 0}}
            >
                <ListItemText
                    primaryTypographyProps={{variant: 'subtitle1', color: 'text.primary'}}
                    primary={currentType ?? 'Content'}
                />
            </ListItem>
        )

        previousType = currentType

        return (
            <Fade
                in
                key={hit.objectID}
            >
                <div>
                    {overline}
                    {hitItem}
                </div>
            </Fade>
        )

    })

    return (
        <Root
            // component='div'
            dense
            sx={{flexGrow: 1, overflowY: 'inherit'}}
        >
            {items}
        </Root>
    )
}