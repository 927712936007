const small = "(a|an|and|as|at|but|by|en|for|if|in|of|on|or|the|to|v[.]?|via|vs[.]?)"
const punct = "([!\"#$%&'()*+,./:;<=>?@[\\\\\\]^_`{|}~-]*)"


const lower = (word: string) => word.toLowerCase()
const upper = (word: string) => word.substr(0, 1).toUpperCase() + word.substr(1)

export const titleCaps = (title: string) => {
    const split = /[:.;?!] |(?: |^)["Ò]/g

    const parts = []
    let index = 0;
    let breakLoop = false

    while (!breakLoop) {
        const m = split.exec(title);

        parts.push(title.substring(index, m ? m.index : title.length)
            .replace(/\b([A-Za-z][a-z.'Õ]*)\b/g, (all) => (
                /[A-Za-z]\.[A-Za-z]/.test(all) ? all : upper(all)
            ))
            .replace(RegExp("\\b" + small + "\\b", "ig"), lower)
            .replace(RegExp("^" + punct + small + "\\b", "ig"), (all, punct: string, word: string) => (
                punct + upper(word)
            ))
            .replace(RegExp("\\b" + small + punct + "$", "ig"), upper));

        index = split.lastIndex;

        if (m) {
            parts.push(m[0])
        } else {
            breakLoop = true
        }
    }

    return parts.join("").replace(/ V(s?)\. /ig, " v$1. ")
        .replace(/(['Õ])S\b/ig, "$1s")
        .replace(/\b(AT&T|Q&A)\b/ig, (all) => (
            all.toUpperCase()
        ));
};


export const slugToTitle = (slug: string) => (
    titleCaps(
        slug.replace(/-/g, ' ').replace(/ and /g, ' & ')
    )
)