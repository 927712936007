import {Button} from "@mui/material";
import React from "react";
import { InsertLink} from "@mui/icons-material";
import {useRouter} from "next/router";
import Link from 'next/link'

export const GetEmbeddedUrlButton = () => {
    const router = useRouter()
    return (
        <Link
            href={{
                pathname: router.pathname,
                query: { ...router.query, embedded: true },
            }}
            passHref
        >
            <Button component='a' endIcon={<InsertLink/>} target="_blank">
                Embedded
            </Button>
        </Link>
    );
}