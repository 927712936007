import {connectHighlight} from "react-instantsearch-dom";
import {HighlightProps} from "react-instantsearch-core";
import React from "react";
import {DocsearchHit, SearchRecord} from "@lib/algolia/record-model";

/* Not exported */
interface HighlightResultPrimitive {
    /** the value of the facet highlighted (html) */
    value: string;
    /** full, partial or none depending on how the query terms match */
    matchLevel: 'none' | 'partial' | 'full';
    matchedWords: string[];
    fullyHighlighted?: boolean;
}

const MatHighlight = connectHighlight(
    ({highlight, attribute: _attribute, hit: _hit}: HighlightProps<SearchRecord>) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const hit: DocsearchHit = {..._hit} // deep clone
        const attribute = _attribute as keyof SearchRecord
        const highlightResult = hit._highlightResult[attribute] as HighlightResultPrimitive | undefined

        if (!highlightResult) {
            return (<></>)
        }

        const parsedHit = highlight({
            highlightProperty: '_highlightResult',
            attribute: attribute,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            hit: hit,
        });
        return (
            <span>{
                parsedHit.map(
                    (part, index) =>
                        part.isHighlighted ?
                            <mark key={index}>{part.value}</mark>
                            :
                            <span key={index}>{part.value}</span>
                )
            }</span>
        );
    }
)

interface Props {
    hit: DocsearchHit
    attribute: keyof SearchRecord
}

export const Highlight = (props: Props) => {
    return <MatHighlight {...props} />
}