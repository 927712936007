import {useSideNav} from "@lib/hooks/use-side-nav";
import Link from "next/link";
import {ButtonBase} from "@mui/material";
import PricefxLogo from "@components/icon/pricefx-logo";
import React, {ReactNode} from "react";
import {styled} from "@mui/material";

const LinkButton = (props: {onClick?:  () => void, 'aria-label'?: string, children: ReactNode}) => (
    <ButtonBase component='a' {...props}/>
)

const Root = styled(LinkButton)(({theme})=>({
    alignSelf: 'stretch',
    padding: theme.spacing(2),
    transition: theme.transitions.create(['background-color']),
    borderRadius:  theme.shape.borderRadius,
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}))

export const LogoButton = () => {
    const [_, setSideNavOpen] = useSideNav()
    const hideSideNav = () => setSideNavOpen(false)
    return (
        <Link href='/' passHref>
            <Root
                onClick={hideSideNav}
                aria-label="Navigate to Home Screen"
            >
                <PricefxLogo sx={{height: '100%'}}/>
            </Root>
        </Link>
    )
}