import {Box, Link} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAlgolia} from "@fortawesome/free-brands-svg-icons/faAlgolia";
import React from "react";


export const PoweredByAlgolia = () => (
    <Link
        href='https://www.algolia.com/'
        sx={{color: 'text.disabled'}}
    >
        Powered By Algolia
        <Box component='span' ml={1}>
            <FontAwesomeIcon icon={faAlgolia}/>
        </Box>
    </Link>
)