import {styled} from "@mui/material";
import {mouseDevice} from "@lib/jss/breakpoints";
import {Box} from "@mui/material";
import {keyMixin} from "@lib/styles/theme-utils";

export const Key = styled(Box)(({theme}) => ({
        ...keyMixin(theme),
        display: 'none',
        [mouseDevice]: {
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
    })
)