import React from "react";
import {Chip} from "@mui/material";
import Typography from "@mui/material/Typography";

export const AppBarTitle = ({children}: { children: React.ReactChild }) => (
    <Chip
        sx={{display: { xs: 'none', sm: 'flex'}, mx: 1}}
        label={<Typography variant='overline'>{children}</Typography>}
        size='small'
        variant='outlined'
    />
)