import {Configure, InstantSearch} from 'react-instantsearch-dom';
import {ALGOLIA_INDEX_NAME, SEARCH_CLIENT} from "@lib/algolia/record-model";
import {SearchBox} from "@components/search/mat-search/search-box";
import {ResultsWithPreview} from "@components/search/mat-search/results-with-preview";
import {Results} from "@components/search/mat-search/results";
import {Box, Divider, SxProps} from "@mui/material";
import React from "react";
import {PoweredByAlgolia} from "@components/search/mat-search/powered-by-algolia";
import {RefinementList} from "@components/search/mat-search/refinement-list";
import Grid from "@mui/material/Grid";
import {HiddenCss} from "@components/layout/generic-layout";
import {SelectedSearchResultIndexProvider} from "@components/context-providers/selected-search-result-index-provider";
import {Theme} from "@mui/material/styles";

type Props = {
    tabs?: string[]
    onClose?: () => void
    disableFacets?: boolean
    sx?: SxProps<Theme>
}

/**
 * A box/panel that provides an input field and an area for search results.
 * @param props
 * @constructor
 */
export const SearchPanel = ({onClose, disableFacets, sx}: Props) => (
    <SelectedSearchResultIndexProvider>
        <InstantSearch
            searchClient={SEARCH_CLIENT}
            indexName={ALGOLIA_INDEX_NAME}
        >
            <Configure
                hitsPerPage={10}
                distinct
                attributesToSnippet={['content']}
            />
            <Grid container sx={sx}>
                {!disableFacets && (
                    <Grid item sm={12} md={3} lg={3}>
                        <HiddenCss smDown>
                            <FacetController/>
                        </HiddenCss>
                    </Grid>
                )
                }
                <Grid item sm={12} md={disableFacets ? 12 : 9} lg={disableFacets ? 12 : 9}
                      sx={{height: '100%', flexDirection: 'column', display: 'flex', flex: 1}}>
                    <SearchBox onClose={onClose}/>
                    <Divider/>
                    <Results>
                        <ResultsWithPreview/>
                    </Results>
                    <Divider/>
                    <Box my={1} mx={2} display='flex' justifyContent='flex-end' alignItems='center'>
                        <PoweredByAlgolia/>
                    </Box>
                </Grid>
            </Grid>
        </InstantSearch>
    </SelectedSearchResultIndexProvider>
)

const FacetController = () => (
    <Grid container  sx={{m: 2}}>
        <Grid item xs={12} sm={4} md={12}>
            <RefinementList attribute='type'/>
        </Grid>
        <Grid item xs={12} sm={4} md={12}>
            <RefinementList attribute='topic'/>
        </Grid>
    </Grid>
)