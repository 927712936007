import React, {ReactNode, useState} from "react";
import {SelectedSearchResultIndexContext} from "@lib/hooks/contexts/selected-search-result-index-context";

export const SelectedSearchResultIndexProvider = ({children}: { children: ReactNode }) => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [maxIndex, setMaxIndex] = useState(0)
    const minIndex = 0

    const limit = (index: number) => Math.max(minIndex, Math.min(index, maxIndex))
    const increment = () => setSelectedIndex(limit(selectedIndex + 1 ))
    const decrement = () => setSelectedIndex(limit(selectedIndex - 1))

    return (
        <SelectedSearchResultIndexContext.Provider value={{
            selectedIndex,
            setSelectedIndex,
            maxIndex,
            setMaxIndex,
            increment,
            decrement,
        }}>
            {children}
        </SelectedSearchResultIndexContext.Provider>
    )
}
