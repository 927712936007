import React from "react";
import {HistoryContext, useHistoryBacker} from "@lib/hooks/contexts/history-context";

export const HistoryProvider = ({children}: { children: React.ReactNode }) => {
    const hValidation = useHistoryBacker()
    return (
        <HistoryContext.Provider
            value={hValidation}
        >
            {children}
        </HistoryContext.Provider>
    )
}