import {SSRProvider, OverlayProvider} from 'react-aria';
import Head from 'next/head'
import type {AppProps} from 'next/app';
import React, {useEffect} from 'react';
import CssBaseline from "@mui/material/CssBaseline";


import {SearchDialog} from "@components/search/mat-search/search-dialog";


import '@lib/fonts/font-bundle'
import '@lib/fonts/typeface-neo-sans-pro.css'
import {PricefxThemeProvider} from "@components/context-providers/pricefx-theme-provider";
import {SideNavProvider} from "@components/context-providers/side-nav-provider";
import {HistoryProvider} from "@components/context-providers/history-provider";
import {EmotionCache} from "@emotion/utils";
import createEmotionCache from "@lib/jss/create-emotion-cache";
import {CacheProvider} from "@emotion/react";
import {GlobalStyles} from "@mui/material";
import {useProgress} from "@components/nprogress";

// Declaring it as a static constant will ensure that the <style> tag generated would not recalculate on each render.
const globalStyles = <GlobalStyles styles={{
    body: {
        '&.loading *': {
            transition: 'none !important',
        },
        '& #nprogress': {
            pointerEvents: 'none',
            '& .bar': {
                backgroundColor: '#FFFFFF',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '4px',
                zIndex: 99999999999999,
            },
        },
    },
}}/>

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export interface MyAppProps extends AppProps {
    emotionCache?: EmotionCache;
}

export default function MyApp({Component, emotionCache = clientSideEmotionCache, pageProps}: MyAppProps) {
    useEffect(() => {
        document.body.classList?.remove('loading')
    }, [])
    useProgress()
    return (
        <CacheProvider value={emotionCache}>
            <Head>
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                />
            </Head>
            <SSRProvider>
                <PricefxThemeProvider>
                    <OverlayProvider id='overlay-provider'>
                        <HistoryProvider>
                            <SideNavProvider>
                                <CssBaseline/>
                                {globalStyles}
                                <SearchDialog/>
                                <Component {...pageProps} />
                            </SideNavProvider>
                        </HistoryProvider>
                    </OverlayProvider>
                </PricefxThemeProvider>
            </SSRProvider>
        </ CacheProvider>
    )
}
