import {Box, Container, Divider, Grid, Link} from "@mui/material";
import React from "react";
import {GetEmbeddedUrlButton} from "@components/asciidoc/copy-embeddded-url";

export const Footer = () => (
    <>
        <Divider/>
        <Container maxWidth="md">
            <Box my={6} mx={2}>
                <Grid container component='footer' spacing={4} justifyContent='center'>
                    <Grid item sm={6} md={4} container justifyContent='center'>
                        © 2021 Pricefx. All Rights Reserved.
                    </Grid>
                    <Grid item sm={6} md={4} container justifyContent='center'>
                        <Link
                            href='https://www.pricefx.com/impressum-privacy-policy/'
                            underline="hover">
                            Privacy Policy & Impressum
                        </Link>
                    </Grid>
                    <Grid item sm={6} md={4} container justifyContent='center'>
                        <Link href='https://www.pricefx.com/terms-conditions/' underline="hover">Terms & Conditions</Link>
                    </Grid>
                    <Grid item sm={6} md={4} container justifyContent='center'>
                        <GetEmbeddedUrlButton/>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    </>
)

export default Footer
