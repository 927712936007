import React, {PropsWithChildren} from 'react'
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Variant} from "@lib/styles/types";


type AttributeProps = {
    timeToRead?: string
}

type Props = PropsWithChildren<{
    variant?: Variant | 'inherit'
    component?: React.ElementType
    className?: string
} & AttributeProps>

const Title = ({children, className, variant, component, timeToRead}: Props) => (
    <Box
        sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            my: 4,
        }}
        className={className}
    >
        <Typography variant={variant ?? 'h1'} component={component ?? 'h1'}>
            {children}
        </Typography>
    </Box>
)

export default Title