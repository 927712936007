import {useState} from "react";

type UnThrottledFunction<A, R> = (...args: A[]) => R

export const useThrottle = <A, R>(func: UnThrottledFunction<A, R> , delay: number) => {
    const [timeout, saveTimeout] = useState<NodeJS.Timeout | undefined>();

    return (...args: A[]) => {
        if (timeout) {
            clearTimeout(timeout)
        }

        const newTimeout = setTimeout(() => {
            func(...args)
            if (newTimeout === timeout) {
                saveTimeout(undefined)
            }
        }, delay);

        saveTimeout(newTimeout);
    }
}