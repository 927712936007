import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {faClock} from "@fortawesome/free-solid-svg-icons/faClock";
import {faProjectDiagram} from "@fortawesome/free-solid-svg-icons/faProjectDiagram";
import {faWalking} from "@fortawesome/free-solid-svg-icons/faWalking";
import {faJava} from "@fortawesome/free-brands-svg-icons/faJava";
import {faConfluence} from "@fortawesome/free-brands-svg-icons/faConfluence";
import {faWordpress} from "@fortawesome/free-brands-svg-icons/faWordpress";
import {faCode} from "@fortawesome/free-solid-svg-icons/faCode";
import {faFileAlt} from "@fortawesome/free-solid-svg-icons/faFileAlt";
import {faRocket} from "@fortawesome/free-solid-svg-icons/faRocket";
import {faTools} from "@fortawesome/free-solid-svg-icons/faTools";
import {faAsterisk} from "@fortawesome/free-solid-svg-icons/faAsterisk";
import {faThumbsUp} from "@fortawesome/free-solid-svg-icons/faThumbsUp";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import {faArchive} from "@fortawesome/free-solid-svg-icons/faArchive";
import HttpIcon from '@mui/icons-material/Http';
import {ComponentType} from "react";

export const ICON_MAPPING: Record<string, IconDefinition | ComponentType> = {
    'Getting Started': faRocket,
    'Concepts': faProjectDiagram,
    'Walkthroughs': faWalking,
    'How-to': faWalking,
    'How To': faWalking,
    'Best Practices': faThumbsUp,
    'Quick Reference': faClock,
    'Tools': faTools,
    'Javadocs': faJava,
    'Groovydocs': faJava,
    'Reference': faAsterisk,
    'Wiki': faConfluence,
    'Learning Center': faWordpress,
    'Labs': faCode,
    'Questions and Answers': faQuestionCircle,
    'Archive': faArchive,
    'REST API': HttpIcon,
}
export const DEFAULT_ICON: IconDefinition | ComponentType = faFileAlt