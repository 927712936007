import {useRouter} from 'next/router'
import {createContext, useContext, useEffect, useState} from 'react'

type HistoryContext = {
    history: string[]
    back: (fallbackRoute?: string) => void
}

export const HistoryContext = createContext<HistoryContext>({} as HistoryContext)

export const useHistoryBacker = (): HistoryContext => {
    const {asPath, push, pathname} = useRouter()
    const [history, setHistory] = useState<string[]>([])

    const back = (fallbackRoute?: string) => {
        for (let i = history.length - 2; i >= 0; i--) {
            const route = history[i]
            if (!route.includes('#') && route !== pathname) {
                void push(route)
                const newHistory = history.slice(0, i)
                setHistory(newHistory)
                return
            }
        }
        if (fallbackRoute) {
            void push(fallbackRoute)
        }
    }

    useEffect(() => {
        setHistory(previous => [...previous, asPath])
    }, [asPath])

    return {history, back}
}

export const useHistory = (): HistoryContext => (
    useContext(HistoryContext)
)