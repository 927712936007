import {connectRefinementList} from "react-instantsearch-dom";
import {Hit, RefinementListProvided} from "react-instantsearch-core";
import {PricefxFacets} from "@lib/algolia/record-model";
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grow} from "@mui/material";
import {slugToTitle} from "@lib/utils/title-utils";
import {RefinementLabel} from "@components/search/mat-search/refinement-label";
import {forwardRef} from "react";

interface Props {
    attribute: keyof PricefxFacets
}

export const RefinementList = ({attribute}: Props) => {
    const MatRefinementList = connectRefinementList(
        ({items, refine, ...props}: RefinementListProvided) => (
            <FormControl component="fieldset">
                <FormLabel component="legend">{slugToTitle(attribute)}</FormLabel>
                <FormGroup>
                    {
                        items.map(props => (
                            <Grow
                                in
                                key={props.label}
                            >
                                <RefinementControl refine={refine} {...props} />
                            </Grow>
                        ))
                    }
                </FormGroup>
            </FormControl>
        )
    )
    return (
        <MatRefinementList
            attribute={attribute}
            transformItems={(items: RefinementItem[]) => items.sort((a, b) => a.label.localeCompare(b.label))}
        />
    )
}

type RefinementItem = Hit<{ count: number, isRefined: boolean, label: string, value: string[] }>

type RefinementControlProps = {
    refine: (value: string[]) => unknown
} & RefinementItem

const RefinementControl = forwardRef(function RefinementControl({
                                                                    label,
                                                                    value,
                                                                    isRefined,
                                                                    count,
                                                                    refine,
                                                                }: RefinementControlProps, ref) {
    return (
        <FormControlLabel
            ref={ref}
            control={
                <Checkbox
                    checked={isRefined}
                    onChange={(_) => {
                        refine(value)
                    }}
                    name={label}/>
            }
            label={<RefinementLabel label={label} count={count}/>}
        />
    )
})