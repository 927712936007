import { colorBoxMixinEmotion} from "@lib/styles/theme-utils-2";
import {Box, Typography} from "@mui/material";

const style = {
    px:1,
    mx: 1,
    ...colorBoxMixinEmotion('info'),
}

export const RefinementLabel = ({label, count}: {label: string, count: number}) => (
        <Box display='inline-flex' alignItems='center'>
            <Typography component='span' variant='body1'>{label}</Typography>
            <Typography component='span' variant='caption' className='count' sx={style}>{count}</Typography>
        </Box>
    )