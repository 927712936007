import {connectStateResults} from "react-instantsearch-dom";
import {NoResults} from "@components/search/mat-search/no-results-placeholder";
import React from "react";

export const Results = connectStateResults(
    ({searchState, searchResults, children}) => {
        const queryExists = searchState && searchState.query
        const searchResultsExist = searchResults && searchResults.nbHits !== 0
        // if (!queryExists && !searchResultsExist) {
        //     return <SkeletonList />
        // }
        if (!searchResultsExist && queryExists) {
            return <NoResults searchQuery={searchState.query}/>
        }
        return <>{children}</>
    }
)
