import {ListItem, ListItemIcon, ListItemText, Skeleton} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import {DocsearchHierarchy, DocsearchHit} from "@lib/algolia/record-model";
import {isDefined} from "@lib/utils/array-utils";
import {touchOnlyDevice} from "@lib/jss/breakpoints";
import Link from 'next/link'
import {DocIcon} from "@components/icon/doc-icon";
import {Snippet} from "react-instantsearch-dom";


export interface Props {
    hit?: DocsearchHit
    onSelect?: () => void
    onClick?: () => void
    selected?: boolean
}

export const getSectionTitles = (hit: DocsearchHit) => (
    hierarchyToArray(hit.hierarchy)
)

const hierarchyToArray = (hierarchy: DocsearchHierarchy, startIndex = 0): string[] => (
    [
        hierarchy.lvl0,
        hierarchy.lvl1,
        hierarchy.lvl2,
        hierarchy.lvl3,
        hierarchy.lvl4,
        hierarchy.lvl5,
    ].splice(startIndex).filter(isDefined)
)

export const HitListItem = (props: Props) => {
    // TODO For pages that are on the same site: preload with next/link
    return props.hit ? (
        <Link href={props.hit.url} passHref>
            <It {...props} />
        </Link>
    ) : (
        <It {...props} />
    )
}

const It = ({hit, onSelect, selected, onClick}: Props) => {
    const handleSelect = () => {
        onSelect && onSelect()
    }
    const handleClick = () => {
        onClick && onClick()
    }
    const title = hit && getSectionTitles(hit)[0]
    return (
        <ListItem
            sx={{[touchOnlyDevice]: {backgroundColor: 'transparent !important'}}}
            component='a'
            href={hit?.url}
            onFocus={() => handleSelect()}
            onMouseOver={(_: unknown) => handleSelect()}
            onClick={(_: unknown) => handleClick()}
            selected={selected}
        >
            <ListItemIcon sx={{color: 'secondary.main'}}>
                {hit ? <DocIcon text={hit.type}/> : <Skeleton variant="circular" width={40} height={40}/>}
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant="h6" component='div'>
                        {title ?? <Skeleton width={300}/>}
                    </Typography>
                }
                secondary={
                    <Typography component='div' sx={{color: 'text.secondary'}}>
                        {/*<HitBreadcrumbs hit={hit} />*/}
                        {hit ? <Snippet hit={hit} attribute='content'/> : <Skeleton width={500} height={'3em'}/>}
                    </Typography>
                }
            />
        </ListItem>
    )
}