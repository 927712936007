import {useEffect, useState} from "react";

export const useOnline = () => {
    const [isOnline, setOnline] = useState(getOnlineStatus())
    const updateNetwork = () => {
        setOnline(getOnlineStatus())
    }
    useEffect(() => {
        window.addEventListener("offline", updateNetwork)
        window.addEventListener("online", updateNetwork)
        return () => {
            window.removeEventListener("offline", updateNetwork)
            window.removeEventListener("online", updateNetwork)
        }
    })
    return isOnline;
}

// Default to true if SSR
const getOnlineStatus = () => typeof window === 'undefined' ? true : window.navigator.onLine