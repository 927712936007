import {createTheme, alpha, Theme, ThemeOptions} from "@mui/material/styles";
import {
    BLACK,
    BLUE_VERY_DARK,
    PFX_GREEN,
    PFX_LIGHT_BLUE,
    PFX_ORANGE,
    PFX_PINK, PFX_UNITY_DARK_BLUE,
    WHITE
} from "@lib/styles/color-constants";
import {Shadows} from "@lib/styles/types";
import {lineBorder, solidBorder} from "@lib/styles/theme-utils-2";
import {PaletteMode} from "@mui/material";
import {mouseDevice} from "@lib/jss/breakpoints";

declare module '@mui/material' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {
    }
}

const headerFontMixin = {
    fontFamily: [
        'NeoSansPro',
        'Roboto',
        'Helvetica',
        'Arial',
        'sans-serif',
    ].join(','),
    fontWeight: 500,
}

const getBackgroundColor = (mode: PaletteMode) => mode === 'light' ? WHITE : BLUE_VERY_DARK
const getDividerColor = (mode: PaletteMode) => mode === 'light' ? 'rgb(234, 238, 243)' : 'rgb(19, 47, 76)'
const getTextColor = (mode: PaletteMode) => mode === 'light' ? 'rgba(0,0,0,0.87)' : WHITE
const alertMixin = (mode: PaletteMode, color: string) => ({
    main: color,
    contrastText: getTextColor(mode),
})

const borderRadius = 4

const getHoverOpacity = (mode: PaletteMode) => mode === 'light' ? 0.04 : 0.08
const getHover = (mode: PaletteMode) => alpha(mode === 'light' ? BLACK : WHITE, getHoverOpacity(mode))
const getSelectedOpacity = (mode: PaletteMode) => mode === 'light' ? 0.08 : 0.16
const getSelected = (mode: PaletteMode, secondary: string) => alpha(secondary, getSelectedOpacity(mode))
const getDisabledOpactiy = (mode: PaletteMode) => 0.38
const getDisabled = (mode: PaletteMode) => alpha(mode === 'light' ? BLACK : WHITE, mode === 'light' ? 0.26 : 0.3)
const getDisabledBackground = (mode: PaletteMode) => alpha(mode === 'light' ? BLACK : WHITE, mode === 'light' ? 0.12 : 0.12)
const getFocusOpactiy = (mode: PaletteMode) => 0.12
const getFocus = (mode: PaletteMode) => alpha(mode === 'light' ? BLACK : WHITE, getFocusOpactiy(mode))
const getActivatedOpacity = (mode: PaletteMode) => mode === 'light' ? 0.12 : 0.24

// Cannot access theme.transition.create until we've created the theme...
const createTransition = (props: string[]) => props.map(prop => `${prop} 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`).join(',')

const buildThemeOptions = (mode: PaletteMode, primary: string, secondary: string): ThemeOptions => ({
    palette: {
        mode: mode,
        primary: {
            main: primary,
            contrastText: '#FFF',
        },
        secondary: {
            main: secondary,
            contrastText: '#FFF',
        },
        background: {
            default: getBackgroundColor(mode),
            paper: getBackgroundColor(mode),
        },
        info: alertMixin(mode, PFX_LIGHT_BLUE),
        success: alertMixin(mode, PFX_GREEN),
        error: alertMixin(mode, PFX_PINK),
        warning: alertMixin(mode, PFX_ORANGE),
        action: {
            hover: getHover(mode),
            hoverOpacity: getHoverOpacity(mode),
            selected: getSelected(mode, secondary),
            selectedOpacity: getSelectedOpacity(mode),
            disabledOpacity: getDisabledOpactiy(mode),
            disabledBackground: getDisabledBackground(mode),
            disabled: getDisabled(mode),
            focus: getFocus(mode),
            focusOpacity: getFocusOpactiy(mode),
            activatedOpacity: getActivatedOpacity(mode),
        },
        contrastThreshold: 1,
        divider: getDividerColor(mode),
    },
    zIndex: {
        mobileStepper: 1000,
        speedDial: 1050,
        drawer: 1100,
        appBar: 1200,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500,
    },
    typography: {
        h1: {
            fontSize: '3.125rem',
            ...headerFontMixin,
            color: mode === 'light' ? primary : WHITE,
        },
        h2: {
            fontSize: '2rem',
            ...headerFontMixin,
            color: mode === 'light' ? primary : WHITE,
        },
        h3: {
            fontSize: '1.75rem',
            ...headerFontMixin,
            color: mode === 'light' ? primary : WHITE,
        },
        h4: {
            fontSize: '1.5rem',
            color: mode === 'light' ? primary : WHITE,
        },
        h5: {
            fontSize: '1.25rem',
            color: mode === 'light' ? primary : WHITE,
        },
        h6: {
            fontSize: '1.125rem',
            color: mode === 'light' ? primary : WHITE,
        },

    },
    shadows: [
        'none',
        ...Array(24)
            .fill(undefined).map((_, i) => (
                    `0px ${Math.round(i / 2)}px ${Math.round(i / 2)}px -${Math.round(i / 3)}px rgb(0 0 0 / 20%),
             0px ${i}px ${Math.round(i * 2)}px ${Math.round(i / 10)}px rgb(0 0 0 / 5%), 
             0px ${Math.round(i / 3)}px ${Math.round(i * 2)}px ${Math.round(i / 3)}px rgb(0 0 0 / 5%)`
                )
            ),
    ] as Shadows,
    shape: {
        borderRadius: borderRadius,
    },
    components: {
        'MuiLink': {
            styleOverrides: {
                root: {
                    color: mode === 'light' ? primary : secondary,
                    textDecoration: 'none !important',
                    position: 'relative',
                    '&::after':{
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '1.3em',
                        borderBottom: lineBorder(secondary),
                        transition: createTransition(['color', 'border-color']),
                    },
                    [mouseDevice]: {
                        '&:not(:hover)': {
                            '&::after':{
                                borderColor: getSelected(mode, secondary),
                            },
                        },
                    },
                },
            },
        },
        'MuiPaper': {
            styleOverrides: {
                root: {
                    transition: createTransition(['background-color', 'color', 'box-shadow']),
                    // Disable lighter color when raised. For example AppBar shouldn't change color on scroll
                    backgroundImage: 'unset',
                },
            },
        },
        'MuiListSubheader': {
            styleOverrides: {
                root: {
                    transition: createTransition(['background-color', 'color', 'box-shadow']),
                },
            },
        },
        'MuiCssBaseline': {
            styleOverrides: {
                html: {
                    '& .Mui-selected': {
                        // Bug in MUI? Selected color used primary color, and not action.selected
                        backgroundColor: getSelected(mode, secondary) + ' !important',
                    },
                    button: {
                        '&:focus': {
                            outline: 'none !important',
                        },
                    },
                    summary: {
                        '&:focus,:focus-visible': {
                            outline: 'none !important',
                        },
                    },
                    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                        width: 12,
                        height: 12,
                        transition: createTransition(['background-color']),
                    },
                    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                        backgroundColor: getFocus(mode),
                        borderRadius: borderRadius,
                    },
                    '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
                        backgroundColor: getDisabled(mode),
                    },
                    '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
                        backgroundColor: getDisabled(mode),
                    },
                    '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: getDisabled(mode),
                    },
                    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
                        backgroundColor: getHover(mode),
                    },
                    '::selection': {
                        // backgroundColor: faintColor(theme, theme.palette.secondary.main),
                        // color: faintColorContrast(theme, theme.palette.secondary.main),
                        backgroundColor: primary,
                        color: WHITE,
                    },
                    // 'overlay' is a valid value in Chrome
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    body: {
                        overflowY: 'overlay',
                        backgroundColor: getBackgroundColor(mode),
                        transition: createTransition(['background-color', 'color']),
                    },
                    pre: {
                        margin: 0,
                    },
                    hr: {
                        border: solidBorder(1, getDividerColor(mode)),
                    },
                    p: {
                        margin: 0,
                    },
                    mark: {
                        color: primary,
                        fontWeight: 700,
                        backgroundColor: 'transparent',
                    },
                    a: {
                        color: 'inherit',
                        textDecoration: 'none',
                    },
                },
            },
        },
    },
})


const createContrastOptions = (theme: Theme): ThemeOptions => ({
    palette: {
        mode: 'dark',
        primary: {
            main: theme.palette.primary.contrastText,
            contrastText: theme.palette?.primary?.main,
        },
        secondary: {
            main: theme.palette.primary.contrastText,
            contrastText: theme?.palette?.primary?.main,
        },
        background: {
            default: theme.palette.primary.main,
            paper: theme.palette.primary.main,
        },
    },
    typography: {
        h1: {
            fontSize: '3.125rem',
            ...headerFontMixin,
        },
        h2: {
            fontSize: '2rem',
            ...headerFontMixin,
        },
        h3: {
            fontSize: '1.75rem',
            ...headerFontMixin,
        },
        h4: {
            fontSize: '1.5rem',
        },
        h5: {
            fontSize: '1.25rem',
        },
        h6: {
            fontSize: '1.125rem',
        },

    },
    shadows: theme.shadows,
    zIndex: theme.zIndex,
    shape: theme.shape,
    components: theme.components,
})

export const lightOptions = buildThemeOptions('light', PFX_UNITY_DARK_BLUE, PFX_LIGHT_BLUE)
export const darkOptions = buildThemeOptions('dark', PFX_UNITY_DARK_BLUE, PFX_LIGHT_BLUE)
export const DEFAULT_THEME_MODE = 'light'

export const lightTheme = createTheme(lightOptions)
export const darkTheme = createTheme(darkOptions)
export const defaultTheme = lightTheme

const lightContrastTheme = createTheme(createContrastOptions(lightTheme))
const darkContrastTheme = createTheme(createContrastOptions(darkTheme))
export const getContrastTheme = (theme: Theme) => (
    theme.palette.mode === 'light' ? lightContrastTheme : darkContrastTheme
)