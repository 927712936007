import { Theme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import React from "react";
import {useSideNav} from "@lib/hooks/use-side-nav";
import {styled} from "@mui/material";

export const appbarTopMixin = (theme: Theme) =>( {
    top: 'calc(56px)',
    '@media (min-width:0px) and (orientation: landscape)': {
        top: 'calc(48px)',
    },
    [theme.breakpoints.up('sm')]: {
        top: 'calc(64px)',
    },
})

const backdropSx = {
    top: 'calc(56px)',
    '@media (min-width:0px) and (orientation: landscape)': {
        top: 'calc(48px)',
    },
    sm: {
        top: 'calc(64px)',
    },
}

interface MobileDrawerProps {
    children: React.ReactNode
}

const Root = styled(Drawer)(({theme})=>({
    '& .MuiDrawer-paper':{
        ...appbarTopMixin(theme),
    },
    '& .MuiDrawer-paperAnchorLeft': {
        borderRight: 'none',
    },
    '& .MuiDrawer-paperAnchorRight': {
        borderLeft: 'none',
    },
}))

export const MobileDrawer = ({children}: MobileDrawerProps) => {
    const [isSideNavOpen, setSideNavOpen] = useSideNav()
    const closeSideNav = () => setSideNavOpen(false)

    // TODO make swipable https://material-ui.com/components/drawers/#drawer
    return (
        <Root
            variant='temporary'
            sx={backdropSx}
            style={{inset: 'auto'}}
            onClose={closeSideNav}
            open={isSideNavOpen}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            anchor='left'
            BackdropProps={{sx: backdropSx}}
        >
            {children}
        </Root>
    )
}