import {Dialog} from "@mui/material";
import {SearchPanel} from "@components/search/mat-search/search-panel";
import React from "react";
import {useSearchDialogOpener} from "@lib/hooks/use-search-dialog-opener";
import {styled} from "@mui/material";

const Root = styled(Dialog)(({theme}) => ({
    '& .MuiDialog-paper':{
        [theme.breakpoints.down('md')]: {
            margin: 0,
            flexGrow: 1,
            borderRadius: 0,
            maxHeight: '100%',
        },
        alignSelf: 'stretch',
        flexDirection: 'row',
    },
}))

export const SearchDialog = () => {
    const [open, setOpen] = useSearchDialogOpener()

    const onClose = () => setOpen(false)

    return (
        <Root
            onClose={onClose}
            open={open}
            fullWidth
            maxWidth='lg'
        >
            <SearchPanel onClose={onClose} disableFacets/>
        </Root>
    )
}
