export const PFX_BLACK = '#231F20'
    export const PFX_DARK_BLUE = '#2955A3'
export const PFX_UNITY_DARK_BLUE = '#004761'
// Not part of marketing's colors, but derived as a mix between the other blue colors, to reach contrast ratio 3:1 against white.
const PFX_MEDIUM_BLUE = '#3278BB'
export const PFX_LIGHT_BLUE = '#41b6e6'
export const PFX_PINK = '#EC6692'
export const PFX_ORANGE = '#f05825'
export const PFX_GREEN = '#ABEB8D'
export const PFX_TEAL = '#2AD6D6'

export const BRIGHT_BLUE = '#0091ea'

export const WHITE = '#FFFFFF'
export const BLACK = '#000000'
export const BLUE_LIGHT = '#073042'
export const DARK_PAPER = '#031f30'
export const BLUE_VERY_DARK = '#111827'
