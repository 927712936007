import {alpha, Theme} from "@mui/material/styles";
import {ThemeColor} from "@lib/styles/types";
import {mixColors} from "@lib/utils/color";

// If we want to modify the Material-UI Theme interfaces at some point, here is how to do it
// declare module "@mui/material/styles/createPalette" {
    // export interface TypeBackground {
    // }
// }

export const HIGHLIGHT_OPACITY = 0.1

const INDICATOR_WIDTH_SCALAR = 0.5
export const indicatorWidth = (theme: Theme) => theme.spacing(INDICATOR_WIDTH_SCALAR)

export const colorBoxMixin = (theme: Theme, color: string) => ({
    color: 'inherit',
    backgroundColor: mixColors(color, theme.palette.background.default, 1 - theme.palette.action.selectedOpacity),
    borderRadius: theme.shape.borderRadius,
})

export const colorBoxMixinEmotion = (color: string) => ({
    color: `${color}.contrastText`,
    backgroundColor: `${color}.main`,
    borderRadius: 1,
})

export const faintGrey = (theme: Theme) => faintColor(theme, theme.palette.grey.A100)

export const faintThemeColor = (theme: Theme, color: ThemeColor) => (
    faintColor(theme, theme.palette[color].main)
)

export const faintColor = (theme: Theme, color: string) => (
    alpha(color, theme.palette.action.selectedOpacity)
)

export const faintThemeColorContrast = (theme: Theme, color: ThemeColor) => (
    faintColorContrast(theme, theme.palette[color].main)
)
export const faintColorContrast = (theme: Theme, color: string) => (
    // theme.palette.mode === 'dark' ? lighten(color, 0.5) : darken(color, 0.5)
    'inherit'
)

export type BorderStyle = 'inset' | 'solid' | 'outset'

export const solidBorder = (widthPx: number, color: string, style: BorderStyle = 'solid') => (
    `${widthPx}px ${style} ${color}`
)

export const lineBorder = (color: string, style: BorderStyle = 'solid') => solidBorder(2, color, style)

export const dividerBorder = (theme: Theme, color: string = theme.palette.divider) => solidBorder(1, color)
