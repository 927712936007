import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, { ComponentType} from "react";
import {DEFAULT_ICON, ICON_MAPPING} from "@lib/configuration/iconography";

const getIcon = (text: string | undefined): IconDefinition | ComponentType | undefined => (
    text !== undefined ? ICON_MAPPING[text] : undefined
)

const getIconWithDefault = (text: string | undefined): IconDefinition | ComponentType => (
    getIcon(text) ?? DEFAULT_ICON
)

const toComponent = (iconDefinitionOrIconComponent: IconDefinition | ComponentType): ComponentType => {
    if ('iconName' in iconDefinitionOrIconComponent) {
        const iconDefinition = iconDefinitionOrIconComponent
        const Icon = () => <FontAwesomeIcon icon={iconDefinition} size='lg'/>
        Icon.displayName = 'Icon'
        return Icon
    } else {
        const Icon = iconDefinitionOrIconComponent
        return Icon
    }
}

export type DocIconProps = {
    text?: string
    noFallback?: boolean // TODO use this
}


export const DocIcon = ({text, noFallback}: DocIconProps): JSX.Element => {
    const i = getIcon(text) ?? (noFallback ? undefined : DEFAULT_ICON)
    if(i === undefined){
        return <></>
    }
    const Icon = toComponent(i)
    return <Icon />
}
