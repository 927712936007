import algoliasearch from "algoliasearch/lite";
import {Hit} from "react-instantsearch-core";
import {notUndefined} from "@lib/utils/types";

// export type GeneralHit = Hit<GeneralRecord>
// export type ContentHit = Hit<ContentRecord>
export type DocsearchHit = Hit<SearchRecord>

export type PricefxFacets = {
    'type'?: string
    'topic'?: string
}

export type SearchRecord = DocsearchRecord & PricefxFacets

// TODO move to docsearch module
export type DocsearchRecord = {
    objectId: string
    content?: string
    hierarchy: DocsearchHierarchy
    anchor: string
    url: string
}

export interface DocsearchHierarchy {
    lvl0: string | undefined | null
    lvl1: string | undefined | null
    lvl2: string | undefined | null
    lvl3: string | undefined | null
    lvl4: string | undefined | null
    lvl5: string | undefined | null
}


// TODO move below

export const ALGOLIA_INDEX_NAME: string = notUndefined(process.env.NEXT_PUBLIC_CONTENT_INDEX_NAME,'NEXT_PUBLIC_CONTENT_INDEX_NAME')
export const ALGOLIA_APPLICATION_ID: string = notUndefined(process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID, 'NEXT_PUBLIC_ALGOLIA_APPLICATION_ID')
export const ALGOLIA_SEARCH_ONLY_API_KEY: string = notUndefined(process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_ONLY_API_KEY, 'NEXT_PUBLIC_ALGOLIA_SEARCH_ONLY_API_KEY')

export const SEARCH_CLIENT = algoliasearch(
    ALGOLIA_APPLICATION_ID,
    ALGOLIA_SEARCH_ONLY_API_KEY
)
