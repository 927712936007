import NProgress from 'nprogress';
import {useRouter} from 'next/router';
import {useEffect} from 'react';
import {ENVIRONMENT} from "@lib/environment";

type Handler = (...evts: unknown[]) => void

export const useProgress = () => {
    const router = useRouter()

    useEffect(() => {
        // TODO: useProgress() does not work well in PWA: it lingers on. Find out why and fix.
        //  In the meantime, disable for production. The pages loads almost instantaneously anyways.
        if(ENVIRONMENT === 'production'){
            return
        }

        let timeout: NodeJS.Timeout

        const start: Handler = () => {
            timeout = setTimeout(() => NProgress.start(), 100);
        };

        const done: Handler = () => {
            clearTimeout(timeout);
            NProgress.done();
        };

        router.events.on('routeChangeStart', start);
        router.events.on('routeChangeComplete', done);
        router.events.on('routeChangeError', done);
        return () => {
            router.events.off('routeChangeStart', start);
            router.events.off('routeChangeComplete', done);
            router.events.off('routeChangeError', done);
        };
    }, [router]);
}
