import React, {useState} from "react";
import {SideNavContext} from "@lib/hooks/contexts/side-nav-context";

export const SideNavProvider = ({children}: { children?: React.ReactNode }) => {
    const state = useState(false)
    return (
        <SideNavContext.Provider
            value={state}
        >
            {children}
        </SideNavContext.Provider>
    )
}