import {decomposeColor, recomposeColor} from "@mui/material/styles";

export const linearGradient = (color1: string, color2: string, angle = '45deg') => (
    `linear-gradient(${angle}, ${color1} 0%, ${color2} 100%);`
)

export const getCappedOpacity = (opacity?: string) => opacity ? decimalToHex(Math.min(Math.max(parseInt(opacity), 0), 255)) : 'FF'
export const getOpacity = (hexColor: string): number => {
    const regex = /^#?([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/ //regular expression to parse string
    const opacityHex = regex.exec(hexColor)?.slice(1)[3] ?? 'FF'  //create array from string 'a' using regex
    return parseInt(opacityHex, 16) / 255.0
}
export const getAverageColor = (hexColorA: string, hexColorB: string) => {
    const regex = /^#?([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/ //regular expression to parse string
    const a = regex.exec(hexColorA)?.slice(1) ?? 'FFFFFFFF'  //create array from string 'a' using regex
    const b = regex.exec(hexColorB)?.slice(1) ?? 'FFFFFFFF' //create array from string 'b' using regex
    let output = '#'
    for (let i = 0; i < 4; i++) {
        const value = Math.floor(
            (
                parseInt(a[i], 16) + //parse decimal from hexadecimal
                parseInt(b[i], 16)   //parse decimal from hexadecimal
            ) / 2                   //perform averaging
        ).toString(16)          //convert back to hexadecimal
        output += (value.length < 2 ? '0' : '') + value //add leading zero if needed
    }
    return output
}

export function decimalToHex(d: number) {
    let hex = Number(d).toString(16);
    const padding = 2
    while (hex.length < padding) {
        hex = "0" + hex;
    }

    return hex;
}

/**
 * both colors must be rgb colors
 * @param colorA
 * @param colorB
 * @param k
 */
export const mixColors = (colorA: string, colorB: string, k: number): string => {
    const {type: typeA, values: valuesB} = decomposeColor(colorA)
    const {type: typeB, values: valuesA} = decomposeColor(colorB)
    if ((typeA !== 'rgb' && typeA !== 'rgba') || (typeB !== 'rgb' && typeB !== 'rgba')) {
        throw new Error(`Function not implemeneted for non-rgb(a) values: typeA=${typeA},typeB=${typeB},valuesA=${valuesA},valuesB=${valuesB}`)
    }
    const mixSingle = (a: number, b: number) => (
        k * a + (1 - k) * b
    )
    return recomposeColor({
        values: [
            mixSingle(valuesA[0], valuesB[0]),
            mixSingle(valuesA[1], valuesB[1]),
            mixSingle(valuesA[2], valuesB[2]),
            mixSingle(valuesA[3] ?? 1, valuesB[3] ?? 1),
        ],
        type: 'rgba',
    })
}