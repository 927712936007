import {DocsearchHit} from "@lib/algolia/record-model";
import {HitBreadcrumbs} from "@components/search/mat-search/hit-breadcrumbs";
import Title from "@components/text/title";
import Typography from "@mui/material/Typography";
import {Highlight} from "@components/search/mat-search/highlight";
import React from "react";
import { Skeleton, Stack} from "@mui/material";
import {getSectionTitles} from "@components/search/mat-search/hit-list-item";


export const HitPreview = ({hit}: { hit?: DocsearchHit }) => {
    const title = hit && getSectionTitles(hit)[0]
    return (
        <Stack
            // sx={{
            //     my: 2,
            //     display: 'flex',
            //     flexDirection: 'column',
            //     alignItems: 'center',
            // }}
        >
            <HitBreadcrumbs hit={hit} justifyContent='center'/>
            <Title variant='h3' component='div'>{title ?? <Skeleton width={300}/>}</Title>
            <Typography>{hit ? <Highlight hit={hit} attribute='content'/> : <Skeleton height='6em'/>}</Typography>
        </Stack>
    )
}