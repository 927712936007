import React, {Dispatch, SetStateAction} from "react";

type SelectedSearchResultIndexHandler = {
    selectedIndex: number
    setSelectedIndex:  Dispatch<SetStateAction<number>>
    maxIndex:  number
    setMaxIndex:  Dispatch<SetStateAction<number>>
    increment: () => void
    decrement: () => void
}

export const SelectedSearchResultIndexContext = React.createContext<SelectedSearchResultIndexHandler>({
    selectedIndex: 0,
    setSelectedIndex: () => {},
    maxIndex: 0,
    setMaxIndex: () => {},
    increment: () => {},
    decrement: () => {},
});