import {useRouter} from "next/router";

export const EMBEDDED_QUERY_PARAM_NAME = 'embedded'
export const EMBEDDED_QUERY_PARAM_SET_VALUE = 'true'

export const useEmbedded = (): boolean => {
    const {query} = useRouter()
    // if(!isReady){
    //     return defaultValue
    // }
    return query[EMBEDDED_QUERY_PARAM_NAME] === EMBEDDED_QUERY_PARAM_SET_VALUE
}
