import React from "react";
import {APP_BAR_MAX_HEIGHT, HiddenCss} from "@components/layout/generic-layout";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {Box, Container, IconButton, useScrollTrigger} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {AppBarTitle} from "@components/layout/app-bar-title";
import {useSideNav} from "@lib/hooks/use-side-nav";
import {LogoButton} from "@components/layout/logo-button";

interface Props {
    fixedMenu: React.ReactNode
    subToolbar: React.ReactNode
    title?: string
    isDrawerEnabled: boolean
}

export const PfxAppBar = ({title, fixedMenu, isDrawerEnabled}: Omit<Props, 'subToolbar'>) => {
    const [sideNavOpen, setSideNavOpen] = useSideNav()
    const toggleSideNav = () => setSideNavOpen(!sideNavOpen)
    return (
        <ElevationScroll>
            <AppBar
                position="fixed"
                sx={{
                    zIndex: 'appBar',
                    maxHeight: APP_BAR_MAX_HEIGHT,
                    position: 'fixed',
                    bgcolor: 'background.default',
                }}
                elevation={0}
            >
                <Toolbar sx={{display: 'flex', alignItems: 'stretch'}}>
                    <Container maxWidth='md'
                               sx={{display: 'flex', alignItems: 'stretch', justifyContent: 'space-between'}}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            {isDrawerEnabled &&
                            <HiddenCss mdUp>
                                <IconButton aria-label="Open drawer" onClick={toggleSideNav} size="large">
                                    <MenuIcon/>
                                </IconButton>
                            </HiddenCss>
                            }
                            <LogoButton />
                            {title && <AppBarTitle >{title}</AppBarTitle>}
                        </Box>
                        {fixedMenu}
                    </Container>
                </Toolbar>
            </AppBar>
        </ElevationScroll>
    );
}

interface ElevationScrollProps {
    children: React.ReactElement;
}

const ElevationScroll = ({children}: ElevationScrollProps) => {
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 6 : 0,
    });
}

