import {DocsearchHit} from "@lib/algolia/record-model";
import Typography from "@mui/material/Typography";
import React from "react";
import {getSectionTitles} from "@components/search/mat-search/hit-list-item";
import {Breadcrumbs, Skeleton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";
import {Property} from "csstype";

interface Props {
    hit?: DocsearchHit
    className?: string
    justifyContent?: Property.JustifyContent
    color?: | 'initial'
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'textPrimary'
        | 'textSecondary'
        | 'error'
}

export const HitBreadcrumbs = ({hit, color, justifyContent, ...props}: Props) => (
    <Breadcrumbs
        color='inherit'
        separator={<FontAwesomeIcon icon={faChevronRight} size='xs'/>}
        component='div'
        sx={{display: 'flex', justifyContent: justifyContent}}
        {...props}
    >
        <Typography variant='body2' component='span' color={color ?? 'textSecondary'}>
            {hit ? getSectionTitles(hit) : <Skeleton width={200}/>}
        </Typography>
    </Breadcrumbs>
)

// export const HitBreadcrumbs = ({hit}: { hit: DocsearchHit }) => (
//     <Typography variant='h6' component='div'>
//         {hierarchyToArray(hit.hierarchy, 1).map((hierarchy, index) => (
//             <>
//                 {index > 0 && <ArrowForwardIosIcon key={index} fontSize='small'/>}
//                 {hierarchy}
//             </>
//         ))}
//         {/*<Highlight hit={hit} attribute="hierarchy"/>*/}
//     </Typography>
// )