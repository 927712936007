import React, {ReactNode, useEffect, useState} from "react";
import {ThemeProvider} from "@mui/material";
import {darkTheme, lightTheme} from "@lib/styles/themes";
import useDarkMode from 'use-dark-mode';
import {useEmbedded} from "@lib/hooks/use-embedded";

export const PricefxThemeProvider = ({children}: { children: ReactNode }) => {
    const [mounted, setMounted] = useState(false)
    const {value: isDark} = useDarkMode(false)
    const isEmbedded = useEmbedded() // Always use light theme in embedded mode
    // When mounted on client, now we can show the UI
    useEffect(() => setMounted(true), [])


    if (!mounted) {
        // To avoid theme flickering, avoid ssr
        return <></>
    }

    return (
        <ThemeProvider theme={isEmbedded || !isDark ? lightTheme : darkTheme}>
            {children}
        </ThemeProvider>
    )
}
